import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Picker as EmojiPicker } from 'emoji-mart';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, TextareaAutosize, Tooltip } from '@material-ui/core';
import { AttachFile, Close, Reply, Warning } from '@material-ui/icons';
import _ from 'lodash';
import * as selector from 'store/selectors';
import 'emoji-mart/css/emoji-mart.css';
import { showModal } from 'store/actions';
import { ReactComponent as AttachIcon } from '../../../../../../assets/img/attach.svg';
import { ReactComponent as SendIcon } from '../../../../../../assets/img/send-button.svg';
import { ReactComponent as InactiveSendIcon } from '../../../../../../assets/img/inactive-send-button.svg';
import { ReactComponent as InactiveAttachIcon } from '../../../../../../assets/img/inactive-attach.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/img/close.svg';
import Timer from './Timer'

import * as chatActions from '../../../../../../store/actions/chatActions';
import { InWorkButton } from '..';

export const FormWrap = styled.div`
  .attach_preview {
    background-color: #fff;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    position: relative;
    z-index: 3;
    width: 100%;
    border-top: 1px solid #eaeaea;
  }
  .reply {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .reply img {
    max-height: 250px;
    object-fit: fill;
  }
  .reply_username {
    font-size: 12px;
    line-height: 17px;
  }
  .reply_preview {
    height: auto;
  }
  .attach_info {
    align-items: center;
    display: flex;
    gap: 20px;
  }
  .reply_icon,
  .attach_icon {
    color: #006ae4;
    fill: #006ae4 !important;
  }
  .attach_close_icon {
    height: 15px;
    height: 15px;
    cursor: pointer;
    color: #a6a6a6;
  }
  .reply_content {
    color: #757575;
    pointer-events: none;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 10px 0 10px 20px;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  position: relative;
  border-top: 1px solid #eaeaea;
  .message-add-attach {
    width: 26px;
    height: 24px;
    background-image: url(/img/paperclip.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-left: 20px;
    input {
      display: none;
    }
  }
  .message-text-input {
    font-size: 14px;
    margin-left: 20px;
    max-height: 340px;
    display: block;
    outline: none;
    flex-grow: 1;
    border: none;
    padding-right: 10px;
    margin-top: 1px;
    resize: none;
    padding-top: 4px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: ${(props) => props.outTextLimit && '#FF0000'};
  }
  .message-add-emoji {
    width: 24px;
    height: 24px;
    background-image: url(/img/emoji.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 1px;
  }
  .message-limit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: #ff7474 solid 1.5px;
    color: #ff0000;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    user-select: none;
  }
  .message-send-button {
    width: 26px;
    height: 24px;
    margin-left: 10px;
    margin-right: 20px;
    cursor: ${(props) => (!props.outTextLimit ? 'pointer' : 'not-allowed')};
  }
  .emoji-mart {
    z-index: 101;
    position: absolute;
    max-width: 100%;
    right: 0;
    top: -309px;
    visibility: ${(props) => (props.showEmoji ? 'visible' : 'hidden')};
    .emoji-mart-bar {
      display: none;
    }
    .emoji-mart-category-list li button:focus {
      outline: none;
    }
  }

  .no-active-provider {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: not-allowed;
    font-size: 14px;
    line-height: 19px;
    color: #757575;
  }

  .message-attach-button {
    width: 22px;
    height: 22px;
    cursor: pointer;

    input {
      display: none;
    }
  }

  .message-attach-button-icon {
    cursor: ${(props) => (props.allowAttachment ? 'pointer' : 'not-allowed')};
  }

  .attach_clear {
    cursor: pointer;
    padding: 5px;
  }

  @media (max-width: 960px) {
    height: 50px;
    .message-text-input {
      font-size: 14px;
    }
    .message-send-button {
      width: 24px;
    }
    .message-add-emoji {
      width: 22px;
    }
  }

  @media (max-width: 400px) {
    .message-add-attach {
      margin-left: 5px;
    }
    .message-text-input {
      margin-left: 10px;
    }
    .message-send-button {
      margin-left: 10px;
      margin-right: 20px;
    }
  }
`;

const SendButtonBadge = styled(Badge)`
  .MuiBadge-anchorOriginTopRightRectangle {
    margin-right: 15px;
  }
`;

const SelectForm = styled.div`
  display: flex;
  flex: 1;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
  .options {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .options > div {
    background: #006ae4;
    color: white;
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 4px;
  }
  .close {
    cursor: pointer;
  }
`;

const ConversationForm = ({ conversation, withFile, mentionUsers, userInStopList,  lastMessage }) => {
  const dispatch = useDispatch();
  const [cursorPosition, setPosition] = React.useState(0);
  const store = useStore();
  const fileInput = useRef();
  const texareaRef = useRef(null);
  const [showEmoji, setShowEmojiStatus] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const { selectedCompany, user, departments } = useSelector((state) => state.session);
  const { attachedFile, message, reply, selectedMsg } = useSelector(
    (state) => state.chat,
  );
 

  const selectedDialog = useSelector(selector.selectCurrentDialog);
  const files = useSelector(
    (state) =>
      _.get(
        state.chat.dialogs.find((x) => x.uuid === selectedDialog),
        'files',
        [],
      ),
    (prev, next) => prev.length !== next.length,
  );

  const accessDialogRouting = useSelector((state) => state.settings.accessDialogRouting);

  const dialogInWork = useMemo(() => {
    const userUUid = _.get(user, "uuid", null)
    const inWork = _.get(conversation, "additional.inWork", []);

    if (userUUid) {
      return inWork?.some((inWorkUuid) => inWorkUuid === userUUid);
    }
    return true;
  }, [conversation, user]);

  const isGroupDialog = useMemo(() => conversation?.type === "group", [conversation?.type]);

  const isEmployee = useMemo(() => {
    const userUuid = _.get(user, "uuid", null);
  
    if (!userUuid) {
      return false;
    }
  
    const isDeveloper = _.get(user, 'group', '') === 'developer';
    if (selectedCompany === "0fe8f996-86ab-455c-8c1c-00c22cda1287" && isDeveloper) {
      return true;
    }

    const employees = selectedCompany in departments 
      ? _.flatMap(departments[selectedCompany], 'users')
      : [];

    return employees.some(({ uuid }) => uuid === userUuid);
  }, [departments, selectedCompany, user]);
  
  /* const canEscape = _.get(selectedProvider, 'settings.parseMode') === 'MarkdownV2'; */
  const { t } = useTranslation();
  const handleSetMessageStore = (textMessage) => {
    dispatch(chatActions.setChatMessage(textMessage));
  };

  useEffect(
    () => () => {
      if (typingTimer) {
        clearTimeout(typingTimer);
        dispatch(
          chatActions.sendTypingEvent({
            type: 'end',
            name: user.name,
            uuidUser: user.uuid,
            uuidDialog: conversation.uuid,
            uuidCompany: selectedCompany,
          }),
        );
        setIsTyping(false);
        setTypingTimer(null);
      }
    },
    [],
  );

  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
        setIsTyping(false);
      }, 2000);
      setTypingTimer(timer);
      dispatch(
        chatActions.sendTypingEvent({
          type: 'start',
          name: user.name,
          uuidUser: user.uuid,
          uuidDialog: conversation.uuid,
          uuidCompany: selectedCompany,
        }),
      );
    } else if (typingTimer) {
      dispatch(
        chatActions.sendTypingEvent({
          type: 'end',
          name: user.name,
          uuidUser: user.uuid,
          uuidDialog: conversation.uuid,
          uuidCompany: selectedCompany,
        }),
      );
      clearTimeout(typingTimer);
      setTypingTimer(null);
    }
  }, [isTyping]);

  useEffect(() => {
    const clickEventHandler = (event) => {
      if (showEmoji) {
        const specifiedElement =
          document.getElementsByClassName('emoji-mart')[0];
        const isClickInside = specifiedElement.contains(event.target);
        if (!isClickInside) {
          setShowEmojiStatus(false);
        }
      }
    };

    const keyDownEventHandler = (event) => {
      if (showEmoji) {
        if (event.key === 'Escape') {
          setShowEmojiStatus(false);
        }
      }
    };

    window.addEventListener('mousedown', clickEventHandler, false);
    window.addEventListener('touchstart', clickEventHandler, false);
    window.addEventListener('keydown', keyDownEventHandler, false);
    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false);
      window.removeEventListener('touchstart', clickEventHandler, false);
      window.removeEventListener('keydown', keyDownEventHandler, false);
    };
  }, [showEmoji]);

  const clearAttach = () => {
    fileInput.current.value = '';
    dispatch(chatActions.clearPostChatAttachment());
  };

  const handleChange = (event) => {
    setIsTyping(true);
    handleSetMessageStore(event.target.value);
    event.preventDefault();
  };

  const handleOnPaste = (event) => {
    let items = [];
    if (event.clipboardData) {
      items = event.clipboardData.items;
    } else {
      items = event.originalEvent.clipboardData.items;
    }

    let blob = null;
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].type.indexOf('image') === 0) {
        blob = items[i].getAsFile();
      }
      blob = items[i].getAsFile();
    }

    if (blob !== null) {
      const formData = new FormData();
      formData.append('file', blob);
      chatActions.postChatAttachmentThroughBody(
        blob.name || 'clipboardfile',
        formData,
        selectedCompany,
      )(dispatch, store.getState);
    }
  };

  // const handleAttachButtonClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleAttach = (event) => {
  //   const file = event.target.files[0];
  //   // todo send file
  // };

  const handleToggleEmoji = () => {
    setShowEmojiStatus(!showEmoji);
  };
  const handleEmojiClick = ({ native }) => {
    const newMessage =
      message.slice(0, cursorPosition) + native + message.slice(cursorPosition);
    dispatch(chatActions.setChatMessage(newMessage));
    setPosition(cursorPosition + native.length);
  };

  const sndMessage = (msg) => chatActions.sndChatMessage(msg)(dispatch);
  const handleSendFiles = () => {
    if (files && files.length > 0) {
      dispatch(chatActions.postChatAttachmentThunk(files, selectedCompany));
    }
  };

  const getSymboLimit = () => {
    if (
      user &&
      user.settings &&
      user.settings.chat_symbols &&
      user.settings.chat_symbols !== '0'
    ) {
      return user.settings.chat_symbols;
    }
    return false;
  };
  const getLastSymboLimit = () => {
    const limit = getSymboLimit();
    if (limit && message.length > limit) {
      const last = message.length - limit;
      return last < 100 ? last : '∞';
    }
    return false;
  };
  const handleSend = () => {
    if (!getLastSymboLimit()) {
      let text = message;
      handleSendFiles();
      let data = {};
      if (mentionUsers.length > 0) {
        mentionUsers.forEach(({ name, id }) => {
          if (name) {
            text = text.replace(`@${name}`, `[${name}](tg://user?id=${id})`);
          }
        });
        if (message !== text) {
          data.parseMode = 'Markdown';
        }
      }
      if (reply) {
        data.reply = reply.uuid;
        dispatch(chatActions.chatReplyMessage(null));
      }
      if (attachedFile) {
        data = {
          ...data,
          fileUrl: attachedFile.url,
          title: attachedFile.originName,
          size: attachedFile.size,
          uuidDialog: conversation.uuid,
        };
        if (text && text.trim().length > 0) {
          data.caption = text;
        }

        if (attachedFile.mimeType.startsWith('audio/')) {
          chatActions.sndChatAudio(data)(dispatch);
        } else if (attachedFile.mimeType.startsWith('image/')) {
          chatActions.sndChatImage(data)(dispatch);
        } else if (attachedFile.mimeType.startsWith('video/')) {
          chatActions.sndChatVideo(data)(dispatch);
        } else {
          chatActions.sndChatFile(data)(dispatch);
        }
        
        clearAttach();
      } else if (text && text.trim().length > 0) {
        data = {
          ...data,
          text,
          uuidDialog: conversation.uuid,
        };
        setShowEmojiStatus(false);
        sndMessage(data);
        handleSetMessageStore('');
      }
      dispatch(chatActions.setChatMessage(''));
    }
  };
  const handleClickCursor = (event) => {
    setPosition(event.target.selectionStart);
  };

  const handleKeyDown = (event) => {
    setPosition(event.target.selectionStart + 1);

    if (event.key === 'Enter') {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        handleSetMessageStore(message);
      } else {
        event.preventDefault();
        handleSend();
      }
    }
    // if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {}
  };
  useEffect(() => {
    handleSetMessageStore(message);
  }, [message]);

  const handleAttach = (evt) => {
    const file = evt.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      chatActions.postChatAttachmentThroughBody(
        file.name,
        formData,
        selectedCompany,
      )(dispatch, store.getState);
    }
  };
  const handleClearAttach = (e) => {
    e.preventDefault();
    clearAttach();
  };
  const handleClearReply = () => {
    dispatch(chatActions.chatReplyMessage(null));
  };
  const handleForward = () => {
    const { uuidMessage, uuidMessengerUser } = conversation.ids;
    dispatch(
      showModal('ForwardModal', {
        msgID: selectedMsg,
        fromID: uuidMessengerUser || uuidMessage,
        providerName: conversation.provider.messenger,
        uuidConnection: conversation.provider.uuid,
      }),
    );
  };
  const handleDelete = () => {
    dispatch(
      showModal('ConfirmDialog', {
        title: t('chatMessages.delete'),
        subtitle: t('chatMessages.confirmDelete'),
        handleConfirm: () => {
          dispatch(
            chatActions.deleteMessage({
              providerName: conversation.provider.messenger,
              messageIds: selectedMsg,
              ids: conversation.ids,
            }),
          );
          dispatch(chatActions.clearSelectedMsg());
        },
      }),
    );
  };

  const getPermissionAttachment = () => {
    if (
      user &&
      user.settings &&
      user.settings.chat_attachments &&
      user.settings.chat_attachments === '1'
    ) {
      return false;
    }
    return true;
  };
  const isActiveProvider = conversation.provider.status
    ? conversation.provider.status === 'active'
    : true;

  return (
    <FormWrap>
      {reply && (
        <div className="attach_preview reply_preview">
          <div className="attach_info">
            <Reply className="reply_icon" />
            <div className="reply">
              <div className="reply_username">{reply.username}</div>
              <div className="reply_content">{reply.content}</div>
            </div>
          </div>
          <Close className="attach_close_icon" onClick={handleClearReply} />
        </div>
      )}
      {withFile && attachedFile && (
        <div className="attach_preview">
          <div className="attach_info">
            <AttachFile className="attach_icon" />
            {attachedFile.originName}
          </div>
          <Close className="attach_close_icon" onClick={handleClearAttach} />
        </div>
      )}
      <FormWrapper
        showEmoji={showEmoji}
        allowAttachment={getPermissionAttachment()}
        outTextLimit={getLastSymboLimit()}
      >
        {accessDialogRouting && isEmployee && !isGroupDialog && !dialogInWork ? <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: 35, width: "100%", color: "gray" }}><div style={{ alignItems: "center", justifyContent: "center", display: "flex", flex: 1 }}>{t("chatConversationForm.workRequiredForButton")}</div><InWorkButton /></div> :
        !isActiveProvider ? (
          <div className="no-active-provider">
            <Warning color="error" fontSize="small" />
            {t('chatConversationForm.noActiveProvider')}
          </div>
        ) : 
        userInStopList ? (
          <div className="no-active-provider">
            <Warning color="error" fontSize="small" />
            {t('chatConversationForm.userInStopList')}
          </div> ) : selectedMsg.length > 0 ? (
          <SelectForm>
            <div className="options">
              <div onClick={handleDelete}>
                {t('common.delete')} {selectedMsg.length}
              </div>
              <div onClick={handleForward}>
                {t('common.forward')} {selectedMsg.length}
              </div>
            </div>
            <div
              className="close"
              onClick={() => dispatch(chatActions.clearSelectedMsg())}
            >
              <CloseIcon />
            </div>
          </SelectForm>
        ) : (
          <>
            {withFile && (
              <label className="message-attach-button">
                <div className="message-attach-button-icon">
                  {getPermissionAttachment() ? (
                    <AttachIcon />
                  ) : (
                    <Tooltip title={t('chatConversationForm.inactiveAttach')}>
                      <InactiveAttachIcon />
                    </Tooltip>
                  )}
                </div>
                {getPermissionAttachment() && (
                  <input type="file" ref={fileInput} onChange={handleAttach} />
                )}
              </label>
            )}
            <TextareaAutosize
              ref={texareaRef}
              minRows={3}
              maxRows={10}
              className="message-text-input"
              value={message}
              placeholder={t('chatConversationForm.inputPlaceholder')}
              onPaste={handleOnPaste}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onClick={handleClickCursor}
            />
            <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
              {accessDialogRouting && isEmployee && !isGroupDialog && dialogInWork && <InWorkButton />}
              {getLastSymboLimit() && (
                <div className="message-limit" onClick={handleToggleEmoji}>
                  {getLastSymboLimit()}
                </div>
              )}
            </div>
            {
              conversation.persone.connections.some(
                (connection) =>
                  connection.ids.uuidConnection === conversation.provider.uuid &&
                  ['gupshup', 'waba'].includes(connection.type)
              ) && <Timer lastMessage={lastMessage} />
            }
            <div className="message-add-emoji" onClick={handleToggleEmoji} />
            {files.length > 0 ? (
              <SendButtonBadge
                max={5}
                badgeContent={files.length}
                color="secondary"
              >
                <div className="message-send-button" onClick={handleSend}>
                  {getLastSymboLimit() ? (
                    <Tooltip
                      title={`${t(
                        'chatConversationForm.characterLimit',
                      )}: ${getSymboLimit()}`}
                    >
                      <InactiveSendIcon />
                    </Tooltip>
                  ) : (
                    <SendIcon />
                  )}
                </div>
              </SendButtonBadge>
            ) : (
              <div className="message-send-button" onClick={handleSend}>
                {getLastSymboLimit() ? (
                  <Tooltip
                    title={`${t(
                      'chatConversationForm.characterLimit',
                    )}: ${getSymboLimit()}`}
                  >
                    <InactiveSendIcon />
                  </Tooltip>
                ) : (
                  <SendIcon />
                )}
              </div>
            )}
            <EmojiPicker
              onSelect={handleEmojiClick}
              showPreview={false}
              set="emojione"
            />
          </>
        )}
      </FormWrapper>
    </FormWrap>
  );
};
ConversationForm.propTypes = {
  conversation: PropTypes.object.isRequired,
  withFile: PropTypes.bool,
  mentionUsers: PropTypes.array.isRequired,
  userInStopList: PropTypes.bool,
  lastMessage:PropTypes.object
};
ConversationForm.defaultProps = {
  withFile: true,
};
export default ConversationForm;
