import { useCallback, useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { checkDialogRoutingAccess, createDialogRoutingAccess, removeDialogRoutingAccess } from "store/actions";

import { Typography, Box } from "@mui/material";
import AppSwitcher from "components/AppSwitcher/AppSwitcher";

export const SettingsStyled = styled('div')(({ theme }) => ({
  marginBottom: '12px',
  minHeight: 'auto !important',
  '.card-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    flexWrap: 'wrap',
    padding: '14px 16px',
    backgroundColor: '#F9F9F9',
    borderRadius: '6px',
  },
  '.card-header': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: '8px',
    marginBottom: '8px',
  },
  '.card-title': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.4',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '70%',
  },
  '.card-description': {
    fontSize: '14px',
    color: '#757575',
  },
}));

function RouteHeader() {
  const dispatch = useDispatch();
  const accessDialogRouting = useSelector((state) => state.settings.accessDialogRouting);

  useEffect(() => {
    dispatch(checkDialogRoutingAccess());
  }, [dispatch]);

  const handleCreateDialogRoutingAccess = useCallback(() => {
    if (accessDialogRouting) {
      dispatch(removeDialogRoutingAccess());
    } else {
      dispatch(createDialogRoutingAccess());
    }
  }, [accessDialogRouting, dispatch]);

  return (
    <SettingsStyled>
      <Box className="card-container">
        <div className="card-header">
          <Typography className="card-title">
            Автоматическая маршрутизация диалогов
          </Typography>
          <AppSwitcher
            onChange={handleCreateDialogRoutingAccess}
            isOn={accessDialogRouting}
          />
        </div>
        <Typography className="card-description">
          <p>
            После включения данной функции вы сможете создать сценарий для маршрутизации диалогов
          </p>
          <p>
            И в персональных чатах появится кнопка <i><b>Взять в работу</b></i>
          </p>
        </Typography>
      </Box>
    </SettingsStyled>
  );
}

export default RouteHeader;
