import update from 'immutability-helper';
import * as actionTypes from '../actions';
import { APP_VERSION } from '../../config';

const initialState = {
  app_version: APP_VERSION,
  language: 'ru',
  isTabCountOn: true,
  sound: {
    isOn: true,
    offTimeMinutes: null,
    offTimeInitiatedDate: null,
  },
  notificationIsOn: true,
  selectedProviders: [],
  disabledProviders: [],
  assigneeFilter: { assignee: null, type: 'all' },
  postMode: false,
  dateFilter: { from: null, to: null },

  customFilter: {},
  dialogStatusFilter: null,
  filterBlockIsOpen: true,
  providersBlockIsOpen: true,
  disabledProvidersBlockIsOpen: false,
  dialogFilteredByGroup: false,
  chatNotifications: [],
  darkMode: false,
  exactSearch: false,
  preferredProvider: null,
  activeUuidMessengerUser: null,

  scripts: [],
  accessesToSettings: {},
  accessDialogRouting: false,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXACT_SEARCH: {
      return update(state, {
        exactSearch: { $set: action.payload },
      });
    }
    case actionTypes.SET_GROUP_FILTER: {
      return update(state, {
        dialogFilteredByGroup: {
          $set: !state.dialogFilteredByGroup,
        },
      });
    }
    case actionTypes.DISABLE_CHAT_NOTIFICATION: {
      return update(state, {
        chatNotifications: { $push: [action.payload.uuidDialog] },
      });
    }
    case actionTypes.ENABLE_CHAT_NOTIFICATION: {
      return update(state, {
        chatNotifications: {
          $set: state.chatNotifications.filter(
            (x) => x !== action.payload.uuidDialog,
          ),
        },
      });
    }
    case actionTypes.ENABLE_DARK_MODE: {
      return update(state, {
        darkMode: { $set: true },
      });
    }
    case actionTypes.DISABLE_DARK_MODE: {
      return update(state, {
        darkMode: { $set: false },
      });
    }
    case actionTypes.SET_IS_TAB_COUNT_STATE:
      return update(state, {
        isTabCountOn: { $set: action.isTabCountStateOn },
      });

    case actionTypes.SET_SOUND_STATE:
      return update(state, {
        sound: { $set: action.soundState },
      });

    case actionTypes.SET_NOTIFICATION_STATE:
      return update(state, {
        notificationIsOn: { $set: action.notificationState },
      });

    case actionTypes.SET_LANGUAGE:
      return update(state, {
        language: { $set: action.language },
      });

    case actionTypes.SET_SELECTED_PROVIDERS: 
      return update(state, {
        selectedProviders: { $set: action.data },
      });
      case actionTypes. SET_USER_ID:
      return update(state, {
        activeUuidMessengerUser: { $set: action.data },
      });


    case actionTypes.SET_DISABLED_PROVIDERS:
      return update(state, {
        disabledProviders: { $set: action.data },
      });

    case actionTypes.SET_ASSIGNEE_FILTER:
      return update(state, {
        assigneeFilter: {
          $set: action.payload,
        },
      });

    case actionTypes.SET_POST_MODE:
      return update(state, {
        postMode: {
          $set: action.payload,
        },
      });

    case actionTypes.SET_CUSTOM_FILTER: {
      return update(state, {
        customFilter: {
          $set: action.filter,
        },
      });
    }

    case actionTypes.SET_DIALOG_STATUS_FILTER:
      return update(state, {
        dialogStatusFilter: { $set: action.status },
      });

    case actionTypes.TOGGLE_FILTER_BLOCK:
      return update(state, {
        filterBlockIsOpen: { $set: action.status },
      });

    case actionTypes.TOGGLE_PROVIDERS_BLOCK:
      return update(state, {
        providersBlockIsOpen: { $set: action.status },
      });

    case actionTypes.TOGGLE_DISABLED_PROVIDERS_BLOCK:
      return update(state, {
        disabledProvidersBlockIsOpen: { $set: action.status },
      });

    case actionTypes.SET_FILTER_STATE_DATE: {
      return update(state, {
        dateFilter: { $set: action.payload },
      });
    }

    case actionTypes.SET_PREFERRED_COMPANY_PROVIDER: {
      return update(state, {
        preferredProvider: { $set: action.uuid },
      });
    }

    case actionTypes.SET_ROUTING_SCRIPTS: {
      return update(state, {
        scripts: { $set: action.scripts },
      });
    }

    case actionTypes.SET_ACCESS_DIALOG_ROUTING: {
      return update(state, {
        accessDialogRouting: { $set: action.payload },
      });
    }

    case actionTypes.SET_ACCESS_TO_SETTINGS: {
      return update(state, {
        accessesToSettings: { $set: action.accessesToSettings },
      });
    }

    default:
      return state;
  }
};

export default settings;
